//common
.white-base {
	float:left;
	background: white;
	margin-top:10px;
	margin-left:10px;
	width : -webkit-calc(100% - 260px) ;
	width : calc(100% - 260px);
	z-index: 2;
	position:relative;
	h1{
		font-weight: 400;
		padding:30px 0 20px 0;
	}
	h2{
		font-weight: 400;
	}
	.submit{
		button{
			margin-left:-15px;
		}
	}
	button{
		margin-top:30px;
		padding-top:12px;
		padding-bottom:12px;
	}
	.content{
		width:75%;
		margin-right:10%;
		margin-left:15%;
		margin-top:50px;
	}
}


// login
#user-form {
	width: 767px;
	margin: 20px auto;
	padding: 30px;
	border: 1px solid $color-d;
	form.login {
		border-bottom: 1px solid $color-d;
		padding-bottom: 30px;
		margin-bottom: 30px;
	}
	label {
		padding-bottom: 5px;
	}
	.panel-heading {
	    padding: 10px 15px ;
	    border-radius: 0px;
	    background-color: transparent;
		border-bottom: transparent;
		text-align: center;
		font-size: 2em;
	}
	.panel-group .panel {
		margin-bottom: 0;
		border-radius: 0px;
	}
	.panel-group {
		border: 2px solid #1a1a1a;
	}
	.panel-body {
		border: 0px;
	}
	.panel-default {
	    border: 0px;
	    box-shadow: none;
	}
}
.remodal {
	#user-form {
		width: auto;
		margin: 0;
		padding: 0;
		border: none;
		h2 {
			padding: 0 0 10px;
		}
	}
}
#register {
	padding: 20px 0;
	h1 {
		margin-bottom: 30px;
	}
	.bg-gray {
		padding: 30px;
	}
}
.side {
	background: $footer;
	color: $gray;
	height: 100%;
	width:250px;
	position: fixed;
	float: right;
	top: 0;
	right: 0;
	z-index: 9999;
	a {
		display: block;
		color: $gray;
		padding: 10px;
		&:hover{
			color:white;
			text-decoration: none;
		}
	}
	ul {
		margin-top: 170px;
		li {
			margin:auto 20px;
			&:last-child {
				margin-top: 20px;
				padding-top: 10px;
				border-top: 1px solid $gray;
			}
		}
	}
}
.back{
	background: $footer;
	height:100%;
	width:100%;
	position:fixed;
	top:0;
	left:0;
	z-index:0;
}

.red-box{
	background: $red;
	height:120px;
	width:120px;
	position:absolute;
	top:0;
	left:0;
	z-index:1;
}


// step

#step {
	overflow: hidden;
	padding-bottom: 30px;
	ul {
		overflow: hidden;
		padding-bottom: 20px;
		li {
			list-style:none;
			display: block;
			float: left;
			width: 14%;
			height: 80px;
			background: $color-d;
			text-align: center;
			padding: 30px 0 0 5px;
			position: relative;
			margin: 0 6% 0 0;
			font-size: 12px;
			text-decoration: none;
			color: $black;
			font-weight:300;
			&:hover{
				background: $color-d;
				&::before {
					border-color: $color-d $color-d $color-d transparent;
				}
				&::after{
					border-top: 40px solid transparent;
					border-bottom: 40px solid transparent;
					border-left: 40px solid $color-d;
				}
     		}
     		&:after {
				content: "";
				border-top: 40px solid transparent;
				border-bottom: 40px solid transparent;
				border-left: 40px solid $color-d;
				position: absolute; right: -40px; top: 0;
			}
			&:before {
				content: "";
				position: absolute;
				margin-top: -30px;
				border-width: 40px 0 40px 40px;
				border-style: solid;
				border-color: $color-d $color-d $color-d transparent;
				left: -40px;
				top: 30px;
			}
			&:first-child {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 1px;
				&:before {
					display: none;
				}
			}
			&.active {
				background: $gray;
				color: $white;
				&:after {
					border-left: 40px solid $gray;
				}
				&:before {
					border-color: $gray $gray $gray transparent;
				}
			}
		}
	}
}
.owner-profile {
	h2 {
		a.btn {
			margin-left: 10px;
			padding: 0 12px;
		}
	}
	ul {
		li {
			float: left;
			margin-bottom:5px;
			dl {
				dt {
					color: $gray;
					font-weight: 200;
					padding: 0 10px;
				}
				dd {
					background: $color-d;
					padding: 5px 10px;
					div {
						text-indent:100%;
						white-space:nowrap;
						overflow:hidden;
					}
				}
			}
		}
	}
}
#ownerindex {
	.patrol-list {
		width: 100%;
		.patrol-add {
			text-align: center;
			float: left;
			position: relative;
			margin-bottom: 30px;
			&:before{
				padding-top:100%;
				content: "";
				display: block;
			}
			a {
				position:absolute;
				top:0;
				left:15px;
				display: block;
				color: $gray;
				border: 1px solid $gray;
				border-radius: 10px;
				width : -webkit-calc(100% - 30px) ;
			  width : calc(100% - 30px);
				height:100%;

				p {
					display: block;
					position: absolute;
					top: 0;
 					right: 0;
 					bottom: 0;
 					left: 0;
					margin:auto;
					height:3rem;
				}
				&:hover {
					background: $red;
					border-color: $red;
					color: $white;
				}
			}
		}
		.patrol-property {
			text-align: center;
			float: left;
			position: relative;
			margin-bottom: 30px;
			&:before{
				padding-top:100%;
				content: "";
				display: block;
			}
			a {
				position:absolute;
				top:0;
				left:15px;
				display: block;
				background: $color-d;
				color: $gray;
				border: 1px solid $color-d;
				border-radius: 10px;
				width : -webkit-calc(100% - 30px) ;
			  width : calc(100% - 30px);
				height:100%;
				text-align: left;
				padding:10% 5% 5% 5%;
				text-decoration: none;
				&.active {
					background: $thin-red;
					&:hover{
						background-color:$red;
					}
				}
			}
		}
	}
}
.owner-content {
	width: auto;
	margin-left: 200px;
	.content {
		margin: 0 auto;
		width: 80%;
		.box-center {
			width: 60%;
			margin: 0 auto;
		}
	}
}

.owner-side-bar {
	position: fixed;
	top: 0;
	left: 0;
	background: $thin-gray;
	width: 200px;
	height: 100%;
	ul {
		overflow: hidden;
		margin-top: 70px;
		font-size: 18px;
		font-weight: 700;
		li {
			padding-left: 10px;
			line-height: 2.3;
			a {
				color: $black;
				display: block;
			}
		}
		li.title {
			background: $backend-side-bar-title;
		}
		li.sub-title {
			padding-left: 25px;
		}
	}
}
/* img upload */
.trimming {
	display:block;
	width:120px;
	height:100px;
	overflow:hidden;
	position:relative;
	z-index:1;
 //    img.thumb  {
	// 	float:left;
	// 	position:absolute;
	// }
}

.imageList {
	clear: both;
}
.patrol {
	td {
		vertical-align: middle;
		padding: 0 5px;
	}
	.patrol-image {
		float: left;
		width: 50%;
		padding: 0 4px;
	}
}
.owner-top {
	img {
		// width: 60%;
		text-align: center;
	}
	.user-menu {
		overflow: hidden;
		padding-bottom: 20px;
		a {
			display: block;
			padding: 20px;
			p {
				padding-top: 5px;
			}
		}
	}
	.alert-default {
		margin: 10px 0;
		border: 1px solid $border;
		> div {
			padding: 20px;
		}
		a {
			display: block;
			background: $red;
			color: $white;
			padding: 20px;
			-webkit-transition: all 0.3s ease;
		    -moz-transition: all 0.3s ease;
		    -o-transition: all 0.3s ease;
			&:hover {
				background: $white;
				color: $red;
			}
		}
	}
}
ul.property-tab {
	border-left: 1px solid $gray;
	li {
		background: $thin-gray;
		border-top: 1px solid $gray;
		border-right: 1px solid $gray;
		border-bottom: 1px solid $gray;
		a {
			display: block;
			color: $thin-black;
			margin-right: 0;
		}
	}
	li.hover{
		border-radius: 0;
		background-color: #d0d0d0;
	}
	li.active {
		background: $white;
		border-bottom: 1px solid $white;
		a {
			background: $white;
		}
	}
}
ul.nav-tabs {
	border-bottom: none;
	> li.active {
		> a {
			border: 1px solid $white;
			border-radius: 0;
			&:hover {
				background-image: none;
				border: 1px solid $white;
			}
		}
	}
}
.property-content {
	padding: 20px;
	border: 1px solid $gray;
	overflow: hidden;
	.border-left {
		padding-bottom: 10px;
	}
	span {
		margin: 0 4px 0 4px;
	}
	.plan, .payment-select {
		margin: 20px 0;
		> div {
			padding: 10px;
			> div {
				border: 3px solid $black;
				padding: 10px;
				input[type = "radio"] {
					display: none;
				}
				&:hover {
					cursor: pointer;
				}
			}
			> div.active {
				border: 3px solid $red;
			}
		}
		img {
			height: 30px;
		}
	}
}
// credit
.creditCardForm {
    background-color: #fff;
    overflow: hidden;
    color: #4c4e56;
	label {
	    width: 100%;
	    margin-bottom: 10px;
	}
	.payment {
	    float: left;
	    font-size: 18px;
	    padding: 10px 25px;
	    margin-top: 20px;
	    position: relative;
		> div.form-group {
			float: none;
		}
		.form-group {
		    float: left;
		    margin-bottom: 15px;
		}
		.form-control {
		    line-height: 40px;
		    height: auto;
		    padding: 0 16px;
		}
		.btn {
		    width: 100%;
		    margin-top: 3px;
		    // font-size: 24px;
		    // background-color: #2ec4a5;
		    // color: white;
		}
		select.form-control {
		    padding: 10px;
		    margin-right: 15px;
			height: 35px;
		}

	}
	.credit-name {
	    width: 100%;
	    margin-right: 10px;
	}
	.CVV {
	    width: 40%;
	}
	#ln {
		margin-left: 5px;
	}
	#year {
		margin-left: 5px;
	}
	#card-number-field {
	    width: 100%;
	}
	#expiration-date {
	    width: 100%;
	}
	#credit_cards {
	    width: 100%;
		img {
			height: 30px;
		}
	}
	#pay-now {
	   width: 100%;
	   margin-top: 25px;
   }
   .b-code, .b-number, .br-code {
	   > div {
		   margin-right: 5px;
	   }
   }
   .b-type {
	   width: 30%;
   }
}

.transparent {
    opacity: 0.2;
}
.list {
	> ul {
		overflow: hidden;
		padding: 20px 0;
		li {
			padding-top: 5px;
		}
	}
}
.patrol-entry {
	margin: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	div {
		float: left;
		text-align: center;
	}
	.entry-step {
		border: 2px solid $black;
		border-radius: 5px;
		width: 25%;
		padding: 10px;
	}
	.entry-arrows {
		width: 10%;
		font-size: 30px;
	}
	.entry-disc {
		width: 30%;
		text-align: left;
	}
}
.thumb {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	height: 100%;
}
@media screen and (max-width: 992px) {
	.white-base {
		.content{
			width: auto;
			margin-right: 25px;
			margin-left: 15px;
			margin-top: 20px;
		}
	}
}
@media screen and (max-width: 991px) {
	#ownerindex {
		margin-top: 20px;
	}
	.owner-content {
		margin-left: 0;
		.content {
			width: auto;
			.box-center {
				width: auto;
			}
		}
	}
	.side{
		width:200px;
	}

	.white-base{
		width : -webkit-calc(100% - 200px) ;
		width : calc(100% - 200px);
	}

}
@media screen and (max-width: 767px) {
	.owner-top {
		img {
			width: 50%;
		}
	}
	.creditCardForm .owner,
    .creditCardForm .CVV,
    .creditCardForm #expiration-date,
    .creditCardForm #credit_cards {
        width: 100%;
    }
    .creditCardForm #credit_cards {
        text-align: left;
    }
    #user-form {
		width: auto;
	}
}
@media screen and (max-width: 480px) {
	.owner-top {
		img {
			width: 100%;
		}
	}
}
