#header-menu.solaNav {
	ul {
		li {
			> a {
				color: $white;
				&:hover {
					background: transparent
				}
			}
		}
	}
}
#general.top {
	margin-top: -50px;
}
#general {
	position: relative;
	button {
		margin-top: 10px;
	}
	a.bg-black {
		position: absolute;
		top: 40px;
		right: 30px;
		display: inline-block;
		padding: 30px;
		text-align: center;
		width: 250px;
		&:hover {
			background: $red;
			-ms-transition: .5s;
			-moz-transition: .5s;
			-webkit-transition: .5s;
			transition: .5s;
		}
	}
	.bg-black {
		opacity: .8;
		border-radius: 5px;
		color: $white;
		text-align: left;
		li {
			padding: 5px 0;
		}
		p {
			border-bottom: 2px solid $white;
			&:nth-child(1) {
				border-bottom: none;
			}
		}
		ul.sp-tag {
			overflow: hidden;
			li {
				float: left;
				padding: 10px;
				a {
					color: $white;
				}
			}
		}
	}
	.font-ll {
		font-weight: 200;
	}
	.agreement {
        text-align: left;
		ul {
			padding: 0;
			li {
				list-style-type: disc;
				margin-bottom: 20px;
			}
		}
		p {
			margin-bottom: 20px;
			b {
				font-size: 16px;
			}
		}
	}
    #what {
        text-align: left;
		h3 {
			margin: 0;
			font-weight: 200;
		}
		.stepBtn {
			overflow: hidden;
			margin-top: 0;
			a {
				border: 2px solid $black;
				text-align: center;
				display: block;
				padding: 10px 0;
				color: $black;
				font-weight: 700;
			}
		}
    }
	#creditEx {
		p {
			line-height: 2;
		}
	}
	#creditNew {
		label {
			line-height: 2;
		}
		.form-control {
			margin-bottom: 5px;
		}
	}
    .btn {
        padding: 10px;
		&:hover {
			background: $red;
			-ms-transition: .5s;
			-moz-transition: .5s;
			-webkit-transition: .5s;
			transition: .5s;
			color: $white;
		}
    }
	.register {
		padding: 0 10px;
	}
	.panel.panel-default {
		padding: 30px 0;
		p {
			margin-top: 10px;
		}
		label {
			margin-top: 5px;
		}
	}
	.uses {
		h2 {
			font-weight: 400;
			letter-spacing: 15px;
			margin: 20px 0 0;
		}
		h3 {
			font-weight: 400;
			margin-bottom: 20px;
		}
		div.step {
			width: 50%;
			float: right;
			padding: 10px 15px;
		}
		.bg-white {
			overflow: hidden;
		}
		b {
			background: $white;
			padding: 15px;
		}
	}
}
.sola-login {
	.sola-top {
		margin-bottom: 0;
	}
}
ul.use {
	padding-left: 25px;
    margin: 20px 0;
	li {
		list-style-type: decimal;
	}
}
@font-face {
  font-family: 'chogokubosogothic';
  src:  url('/fonts/chogokubosogothic_5.ttf?18drpv') format('truetype');
  font-weight: normal;
  font-style: normal;
}
#sola {
    font-weight: 200;
}
#solaRegister {
	padding-left: 0;
	padding-right: 0;
	z-index: 999;
	ul {
		overflow: hidden;
	}
	ul.solaSelect {
		li {
			float: left;
			width: 50%;
			padding-bottom: 10px;
			&:last-child {
				border-left: 1px solid $box-shadow;
			}
		}
		li.active {
			a {
				font-weight: 700;
				border-bottom: 4px solid $black;
				padding-bottom: 10px;
			}
		}
	}
	form.general-register{
		margin: 15px 0 0;
	}
	[class*="col-"], [class^=col-] {
		padding: 10px 10px 0 0;
	}
}
.sola-top {
    width: 100%;
    background-image: url('/images/sola-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 -30px;
    padding: 100px 0 30px;
	margin-bottom: 0;
    color: $white;
	// position: relative;
    h2, .properties {
        font-weight: 700;
        font-family: 'chogokubosogothic';
    }
    label {
        text-align: right;
        font-size: 12px;
        padding-right: 10px !important;
		line-height: 25px;
    }
    .sola-what {
        margin-top: 80px;
        i {
            color: $sola-color;
        }
        a {
			margin: 0 auto;
			display: block;
			background: $white;
			width: 200px;
			height: 200px;
			color: $sola-color;
			border-radius: 100%;
			padding-top: 80px;
            span {
                font-weight: 700;
            }
        }
    }
    .container {
        width: auto;
        padding: 0 50px;
		[class*="col-"], [class^=col-] {
            z-index: 999;
        }
    }
    .bg-white {
        border-radius: 5px;
        padding: 30px 30px 40px;
        text-align: center;
        color: $black;
        [class*="col-"], [class^=col-] {
            padding: 0;
        }
        .btn {
            width: 70%;
            margin: 10px auto;
        }
    }
    .form-group {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 10px;
    }
    .form-control {
        height: 25px;
		border: 2px solid $border;
		border-radius: 0;
		padding: 0 12px;
    }
	.properties {
		margin-bottom: 10px;
		padding-left: 0;
		padding-right: 0;
	}
	.sola-user {
        border-top: 2px solid $color-c;
        padding: 10px 0;
    }
    .bg-black.content, .bg-white.content {
        width: 680px;
        margin: 30px auto;
        padding: 30px 70px;
    }
}
.sola-top.login {
	.container {
		width: 700px;
		.bg-white {
			margin-top: 30px;
		}
	}
}
#sola-contact {
    margin-top: 10px;
}
.sola-top .form-control.checkError {
	border-color: $red;
	position: relative;
}
.checkNameS, .checkNameE, .checkTelS, .checkTelE, .checkEmailS, .checkEmailE, .checkEmailConfS, .checkEmailConfE, .checkPasS, .checkPasE {
	width: 20px;
	height: 20px;
	border-radius: 100%;
	position: absolute;
	top: 0;
	right: 0;
	i {
		color: $white;
	}
}
.checkNameE, .checkTelE,.checkEmailE, .checkEmailConfE, .checkPasE {
	background: $red;
}
.checkNameS, .checkTelS, .checkEmailS, .checkEmailConfS, .checkPasS {
	background: $checkS;
}
@media screen and (min-width: 993px) and (max-width: 1139px) {
    .sola-top {
        label {
            font-size: 11px;
        }
        .sola-what {
            a.btn {
                width: 50%;
            }
        }
        .bg-white {
            padding: 20px 10px 0;
        }
    }
}
@media screen and (max-width: 992px) {
	#general.patrol {
		.sola-top{
			ul.patrolRisk, .patrolBtn a {
				width: auto;
			}
			ul.patrolRisk {
				li {
					.left{
						&:nth-child(odd) {
							width: 95%;
							font-size: 16px;
						}
						&:nth-child(even) {
							width: 5%;
						}
					}
				}
			}
			.patrolLogo {
				position: static;
				bottom: 0;
				left: 0;
				padding: 0 20px;
				line-height: 120px;
			}
		}
	}
	.sola-login {
		.sola-top {
			margin-bottom: -30px;
		}
	}
    .sola-top {
        label {
            text-align: left;
            margin-bottom: 5px;
        }
        .sola-what {
            a.btn {
                width: 50%;
            }
        }
        .container {
            padding: 0 0 30px;
        }
        .bg-white {
            padding: 20px 25px 0;
        }
    }
}
@media screen and (max-width: 767px) {
	#general.patrol {
		.sola-top {
			.sola-what {
				margin-top: 20px;
				a {
					padding: 45px 5px 0;
					font-size: 16px;
				}
			}
		}
		.font-l {
			font-size: 16px;
		}
		.font-ll {
			line-height: 2;
		}
	}
	#general {
		a.bg-black {
			position: static;
			display: block;
			width: 70%;
			margin: 20px auto 0;
			padding: 20px 10px;
		}
		.uses {
			div.step {
				width: 100%;
				float: none;
				padding: 0;
			}
			p {
				img {
					width: 45px;
				}
			}
		}
		.agreement {
			ul {
				width: auto;
				margin: 0 auto;
                padding: 0 10px;
			}
		}
		.register {
			padding: 0;
		}
	}
	ul.use {
		padding-left: 25px;
        margin: 20px 0;
	}
	#solaRegister {
		padding-left: 10px;
		padding-right: 10px;
	}
    .sola-top {
        .sola-what {
            padding: 30px 0;
			a {
				width: 150px;
				height: 150px;
				padding-top: 59px;
			}
        }
        .bg-black.content, .bg-white.content {
            width: auto;
            margin: 30px 10px;
            padding: 30px 20px;
        }
		.properties {
			padding-left: 0;
			padding-right: 0;
		}
    }
	.sola-top.login {
		padding: 0;
		.sola-login {
			padding: 0;
		}
		.container {
			width: auto;
		}
	}
}
@media screen and (max-width: 480px) {
	#general.patrol {
		.sola-top{
			ul.patrolRisk {
				li {
					line-height: 1.4;
				    padding-top: 15px;
					.left{
						&:nth-child(odd), &:nth-child(even) {
							font-size: 14px;
						}
					}
				}
			}
		}
		.registerbtn {
			img {
				width: 100%;
			}
		}
		.stepBtn {
			margin-top: 0;
			margin-bottom: 20px;
		}
		.red.scroll {
			text-align: right;
			display: block;
		}
	}
	.sola-top {
		.bg-white {
			.btn {
				width: 80%;
			}
		}
	}
}
