.patrol-entry-form {
	#propertyForm {
		h3 {
			padding: 15px 0 10px 0;
		}
		input {
			margin-bottom: 10px;
		}
		img{
			margin-top: 30px;
		}
		span {
			float: left;
			width: 50%;
			padding-right: 10px;
			margin: 0;
		}
		button {
			border: none;
			padding: 12px 0;
		}
		.building-info {
			padding-top: 6px;
		}
		.border {
			margin-bottom: 20px;
			padding-top:20px;
			clear: both;
		}
		#zip {
			width: 90%;
		}
		.ground {
			float: left;
			width: auto;
			padding: 0 5px;
		}
		.ground-input {
			float: left;
			width: 15%;
			padding: 0 5px;
		}
		.ground-item {
			
		}
		.btn-border{
			padding:5px 0;
		}
		.bg-gray {
			padding: 30px;
			border-radius: 10px;
			overflow: hidden;
			margin-bottom: 30px;
			span {
				margin-bottom: 10px;
			}
		}
		.ground-item {
			padding: 10px 0;
			span {
				float: none;
				width: auto;
				> input {
					margin-left: 5px;
					width: 10%;
				}
			}
			label {
				vertical-align: middle;
				display: inline-block;
			}
			input {
				vertical-align: middle;
				display: inline-block;
				width:6%;
				margin-right: 5px;
				margin-bottom: 0;
			}
		}
		.btn {
			padding: 12px;
		}
		.btn-block {
			border: 1px solid  $gray;
		}
		.active {
			background: $red;
			border: 1px solid $red;
			color: $white;
		}
		.bank-step  {
			text-align: center;
			.bg-white {
				width: 100%;
				padding: 10px;
				margin-bottom: 20px;
			}
		}
		.plan{
			&>p{
				margin-bottom: 10px;
			}
		}
		.payment-select {
			span {
				display: block;
				float: none;
				width: 100%;
			}
		}
	}
}
#result {
	h2, h3 {
		padding-top: 20px;
		padding-bottom: 10px;
	}
	form {
		> div {
			padding-left: 0;
			padding-right: 0;
			input {
				width: 95%;
			}
			button {
				margin-top: 11px;
				padding: 10px;
			}
		}
	}
	.bg-gray {
		padding: 0 20px 20px;
	}
	.info-offer {
		margin-top: 20px;
		margin-left: -15px;
		h3 {
			padding-bottom: 0;
		}
	}
	.info-pay {
		margin-top: 20px;
		margin-right: -15px;
	}
	.info-change {
		padding-top: 10px;
		margin-right: -15px;
		h2 {
			padding-top: 10px;
		}
	}
	.patrol-result {
		margin-top: 20px;
		border: 1px solid $color-d;
		h2 {
			padding: 10px;
		}
		[class^="col-"], [class*=" col-"] {
			padding: 0;
		}
		.bg-gray {
			padding: 0 0 20px;
		}
	}
	.result-date {
		padding: 10px 10px 0;
	}
	.specifics {
		.room-type {
			border-bottom: 3px solid $border;
			padding-top: 10px;
			padding-bottom: 10px;
			overflow: hidden;
			h3 {
				padding: 12px 0 0;
			}
			span {
				vertical-align: middle;
				margin-right: 5px;
			}
		}
		div.specific {
			margin-bottom: 0;
			padding: 10px;
			&:nth-child(even) {
				background-color: $white;
			}
			> div {
				> div {
					padding-bottom: 5px;
					a {
						cursor: pointer;
					}
				}
			}
		}
	}
}
#identi {
	.gray-btn {
		height: 250px;
		margin-top: 10px;
		&:hover {
			background-color: $red;
			border-color: $red;
		}
	}
}
#evidence {
	.room {
		clear: both;
		padding-bottom: 20px;
	}
	.room-image {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 200px;
		height: 200px;
		float: left;
		margin-bottom: 5px;
		margin-right: 10px;
	}
}
.result-img {
	overflow: hidden;
	margin-bottom: 10px;
	h3 {
		text-align: left;
		padding-bottom: 10px;
	}
	.thumb {
		width: 200px;
		height: 200px;
		float: left;
	}
}
#propertyForm {
	.addBtn {
		position: relative;
	}
	.remove {
		position: absolute;
		top: 35px;
		right: 20px;
		background-color: $thin-red;
		color: $white;
		width: 20px;
		height: 20px;
		border-radius: 100%;
		cursor: pointer;
		&:hover {
			background-color: $red;
		}
	}
	.form-control {
		height: 25px;
		margin-bottom: 5px;
	}
}
#common-images {
	overflow: hidden;
	.col-xs-3 {
		margin-bottom: 10px;
	}
	input {
		margin-bottom: 14px;
	}
	p {
		margin-bottom: 10px;
	}
}
.preview-wrap {
	width:auto;
	height:150px;
	border: 1px solid $gray;
	margin-bottom: 5px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $gray;
	.glyphicon.glyphicon-plus {
		padding-top: 25px;
	}
}
#upload {
	.btn-border {
		padding: 6px 12px;
		line-height: 1.428571429;
		font-size: 14px;
	}
}
.upload-btn {
	position: absolute;
	top: 30px;
	left: 15px;
	background: transparent;
	font-weight: normal;
	width: 200px;
	height: 150px;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	color: $gray;
}
#patrol-list {
	ul {
		overflow: hidden;
		margin-top: 10px;
		border: 1px solid $border;
		li {
			overflow: hidden;
			padding: 10px;
			background: $white;
			&:nth-child(even) {
				background: $color-e;
			}
		}
	}	
}
