.logo {
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -37px;
}

.content-social {
	padding: 5px 0 !important;
}
.social {
	text-align: center;
}
.social a {
    display: inline-block;
    color: #fff;
    background-color: #000;
    font-size: 20px;
    padding-top: 5px;
    margin: 0 18px;
    width: 38px;
    height: 38px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
}
/* top */
.autoSelect {
	select {
		display: none;
	}
}
.today {
	font-weight: 700;
	color: #8f8f8f;
	margin-bottom: 10px;
}
.count {
	margin-top: 20px;
	overflow: hidden;
}
.count img {
	width: 48%;
}
.count div {
	padding: 0;
}
.count b {
	font-size: 17px;
}
i.arrow {
	font-size: 40px;
}
.illegal {
    border: 2px solid #1a1a1a;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 10px;
}
.illegal h2 {
	background: #FFFF0B;
	border-bottom: 2px solid #1a1a1a;
	margin-bottom: 10px
}
.form {
	margin: 10px auto ;
	button[disabled] {
		cursor: not-allowed;
	}
}
.form input {
	margin: 10px 0;
}
.form h3 {
	margin-top: 10px;
}
.form .btn-report {
    color: #000;
    margin: 0 auto;
    padding: 10px 0 10px 0;
    background: #FD0006;
    width: 40%;
    line-height: 0.7;
}

/* side */
#side {
	border-left: 1px solid #1a1a1a;
}
#side .sidetitle {
	padding: 10px;
	color: #fff;
}
#side ul.homestay {
	overflow: hidden;
}
#side ul.homestay li {
	float: left;
	width: 50%;
	text-align: center;
	height: 60px;
	padding: 10px;
}
#side p {
	font-weight: 700;
}
ul.new-report  {
	overflow: hidden;
}
ul.new-report li {
	border-bottom: 1px solid #1a1a1a;
	padding:  10px;
}
ul.black {
	background-color: #222;
}
.fact {
	font-family: 'Droid Serif';
}
/* what */

/* contact */
.contact{
	max-width: 500px;
 	h3 {
		margin-top: 10px;
	}
}
.submit {
	text-align: center;
}
#map{
	width:auto;
	height:400px;
}
.inquiry-send-btn {
	padding: 20px;
}
.footer-text {
    padding-top: 15px;
}
.footer-text a{
	color: #fff;
}



.uploaded-image-index {
	overflow: hidden;
	margin: 10px 0;
}
ul.uploaded-image {
	li {
		width: 25%;
		float: left;
		position: relative;
		padding: 10px;
		input.btn {
			margin: 10px 0 0;
			position: absolute;
			bottom: 0;
			left: 0;
		}
	}
}
.top-service{
	padding-top: 30px;
}

@media screen and (max-width: 767px) {
	#map{
		width:auto;
		height:320px;
	}
	#send-btn{
		margin-bottom: 20px;
    	width: 81%;
	}
	/* side */
	#side {
		border-right: 1px solid #1a1a1a;
	}
	.top-service{
		padding-top: 5px;
	}
	.count {
		margin-bottom: 20px;
	}
	.count img {
		width: 70%;
	}
	.report-fact b{
		font-size: 14px;
	}
	.good-bye b{
		font-size: 14px;
	}
	.arrow-area i{
		padding-top: 5px;
		font-size: 24px;
	}
}

@media screen and (max-width: 480px) {
	.top-service {
		padding-left: 0;
		padding-right: 0;
	}
	.report-fact b{
		font-size: 11px;
	}

}
