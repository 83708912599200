@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjapanese.css);
@import "backend";
@import "preVer";
@import "user";
@import "patrol";
@import "what";
@import "top";
@import "complaint";
@import "report";
@import "sola";
@import "common";
