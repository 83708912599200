#general.patrol {
	h3 {
		font-weight: 400;
		padding: 10px 0;
	}
	.sola-top {
		background: none;
		position: relative;
		color: $black;
		h2 {
			margin: 20px 0 10px;
		}
		h1, h2, .properties {
			font-family: $fontType1;
			font-weight: 400;
		}
		ul.properties {
			text-align: left;
			padding-left: 100px;
			li {
				list-style-type: disc;
			}
		}
		ul.patrolRisk {
			width: 500px;
			margin: 0 auto;
			a {
				display: block;
				&:hover {
					color: $black;
					text-decoration: none;
				}
			}
			li {
				border: 1px solid $black;
				padding: 10px;
				margin: 5px 0;
				height: 65px;
				line-height: 40px;
				.left {
					&:nth-child(odd) {
						width: 90%;
					}
					&:nth-child(even) {
						width: 10%;
					}
				}
				i {
					color: $red;
				}
			}
		}
		.sola-what {
			margin-top: 20px;
			a {
				background: $red;
				color: $white;
				padding: 60px 10px 0;
				font-weight: 400;
			}
			i {
				color: $white;
			}
		}
		.patrolLogo {
			background: $black;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			text-align: center;
			height: 100px;
			line-height: 130px;
		}
		.patrolBtn {
			margin: 15px 0 10px;
			a {
				margin: 0 auto;
				width: 500px;
				background: $black;
				color: $white;
				display: block;
				text-align: center;
				padding: 10px;
			}
		}
	}
	.registerbtn {
		img {
			width: 420px;
		}
	}
	.uses {
		h2 {
			letter-spacing: 3px;
		}
		b {
			background: transparent;
		}
	}
	#what {
		p, h3 {
			padding: 20px 0;
		}
	}
}

#what {
	font-size: 14px;
	h3{
	    margin: 30px 0 10px;
		font-size: 20px;
	}
	.minpakuWhat {
		font-size: 18px;
		div {
			background: $color-e;
			border-radius: 5px;
			border: 1px solid $color-c;
			padding: 5px 10px;
			line-height: 1.3em;
			width: 72%;
			margin: 5px auto 5px 120px;
			font-size: 20px;
			a {
				display: block;
				text-align: right;
				i {
					font-size: 1em;
				}
			}
		}
	}
	.minpakuWhatImg {
		margin-bottom: 10px;
		img {
			width: 50%;
		}
	}
	.minpoliMark{
		img {
			width: 100%;
		}
	}
	.registerbtn{
		margin: 20px 0;
		img {
			width: 60%;
			display: block;
			margin: 0 auto;
		}
	}
	ul.registersteps{
		li {
			margin-bottom: 5px;
			p {
				font-weight: normal;
			}
		}
	}
	ul.list {
		margin: 20px 0;
	}
}
.sola-top {
	.patrol-account {
		position: absolute;
		top: 100px;
		right: 50px;
		z-index: 99999;
		a {
			display: block;
			color: $black;
			width: 100%;
			padding: 10px 20px;
		}
	}
}
.patrol {
	font-size: 16px;
}
.patrol-account {
	text-align: center;
	a {
		border: 2px solid $black;
		color: $white;
		padding: 10px;
		width: 50%;
		margin: 0 auto;
		-ms-transition: .5s;
		-moz-transition: .5s;
		-webkit-transition: .5s;
		transition: .5s;
		&:hover {
			background: $black;
			color: $white;
		}
	}
}
#merits {
	img {
		width: 200px;
	}
}
.content.uses {
	.col-xs-12 {
		padding: 0;
	}
	.chart {
		> div {
			padding-bottom: 20px;
			p {
				padding-bottom: 10px;
			}
		}
		.result {
			ul {
				li {
					padding: 5px 0;
					b {
						padding: 0 !important;
					}
				}
			}
		}
	}

}
.what-menu {
	position: fixed;
	left: 1.5%;
	top: 3%;
	overflow: hidden;
	z-index: 1991;
	padding: 1em;
	&.expanded {
		left: -1rem;
	}
	.hide {
		display: none;
	}
	.description {
		margin: 0.5rem;
		text-align: center;
		span {
			cursor: pointer;
			color: $red;
			&:hover {
				color: $red-lighter;
			}
			i {
				margin-right: 0.5em;
			}
		}
	}
	.what-menu-body {
		margin: 0 auto;
		transition: all 0.75s ease;
		//-moz-transition: all 0.75s ease;
		//-webkit-transition: all 0.75s ease;
		//-o-transition: all 0.75s ease;
		//-ms-transition: all 0.75s ease;
		&.minimized {
			width: 3.5rem;
			height: 3.5rem;
			background: $red;
			color: $white;
			font-size: 1.25rem;
			padding: 0.8rem;
			border-radius: 50%;
			text-align: center;
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
			-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
			-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
			&:hover {
				background-color: $red-lighter;
			}
		}
		&.expanded {
			background: $white;
			color: $black;
			font-size: 1rem;
			padding: 0.5rem 0.75rem 0.5rem 0.5rem;
			border-radius: 0.25rem;
			text-align: left;
			box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
			-moz-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
			-webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.25);
		}
		.what-menu-collapsed, .what-menu-toggled {

		}
		.what-menu-collapsed {
			cursor: pointer;
		}
		.what-menu-toggled {
			.what-menu-header {
				color: $red;
			}
			.what-menu-content {

			}
		}
	}
	ul {
		li {
		 	a {
				cursor: pointer;
				display: block;
				padding: 10px;
				background: $white;
				color: $gray;
				font-size: 13px;
				transition: all 0.25s ease;
				-moz-transition: all 0.25s ease;
				-webkit-transition: all 0.25s ease;
				-o-transition: all 0.25s ease;
				-ms-transition: all 0.25s ease;
				&:hover {
					color: $black;
				}
			  	&.current {
				  	font-weight: 500;
					color: $black;
				}
				i {
					font-size: 21px;
					margin-right: 15px;
				}
			}
	  	}
	}
}

// LP
#patrol-top {
	margin-top: 0;
	height: 400px;
	text-align: center;
	width: 100%;
	background-image: url('/images/patrol-top-top.png'),url('/images/patrol-top-bottom.png');
	background-size: auto 25%,auto 45%;
	background-repeat: repeat-x,no-repeat;
	background-position: center top,center bottom;
	display: flex;
    justify-content: center;
    align-items: center;
    .container {
    	padding: 0 100px 0 100px;
    	div{
	    	position:relative;
			img{
				position:absolute;
				&.logo-v2{
					top:50%;
					left:0;
					width:38%;
					transform: translateY(-50%);
				}
				&.patrol-title{
					top:50%;
					right:0;
					width:58%;
					transform: translateY(-50%);
				}
			}
		}
	}
}
#patrol {
	padding-bottom: 80px;
	padding-top: 20px;
	position: relative;
	box-shadow: $section-box-shadow;
	h1 {
		> span {
			width: 15px;
			height: 15px;
			border-radius: 100%;
			display: inline-block;
			border: 4px solid $red;
			margin-right: 10px;
		}
	}
	ul {
		li {
			a {
				display: block;
				padding-bottom: 10px;
				color: $black;
				&:hover {
					color: $red;
					text-decoration: none;
				}
				i {
					padding-right: 15px;
				}
			}
		}
	}
	div.more {
		position: absolute;
		bottom: -40px;
		left: 0;
		width: 100%;
		text-align: center;
		color: $red;
		overflow: hidden;
		a {
			background: $red;
			color: $white;
			line-height: 60px;
			font-size: 30px;
			width: 60px;
			height: 60px;
			display: inline-block;
			border-radius: 100%;
			margin: 10px;
			box-shadow: $section-box-shadow;
		}
	}
}
#column {
	padding: 40px 0 60px;
	h2 {
		text-align: center;
		padding-bottom: 40px;
		span {
			background: $black;
			color: $white;
			padding: 5px 10px;
			margin-right: 10px;
			font-size: 14px;
		}
	}
	ul {
		margin-left: 30px;
		li {
			list-style: disc;
			font-weight: 700;
		}
	}
}
#merit {
	position: relative;
	z-index: 2;
	padding: 20px 0 60px;
	box-shadow: $section-box-shadow;
	div.merit {
		> div.box {
			box-shadow: $card-box-shadow;
			-moz-box-shadow: $card-box-shadow;
			-webkit-box-shadow: $card-box-shadow;
			border-radius: $card-box-radius;
			overflow: hidden;
			margin-bottom: 20px;
			padding: 30px 10px;
			h3 {
				font-weight: 700;
				font-size: 22px;
				margin-bottom: 0.5em;
			}
			img {
				padding: 30px 10px;
			}
		}
	}
}
#voice {
	padding: 20px 0 60px;
	h3 {
		padding: 20px 25px;
		font-size: 20px;
	}
	.voice {
		> div.voice-card {
			background: $white;
			box-shadow: $card-box-shadow;
			-moz-box-shadow: $card-box-shadow;
			-webkit-box-shadow: $card-box-shadow;
			border-radius: $card-box-radius;
			margin-bottom: 20px;
			overflow: hidden;
			> div:first-of-type {
				border-right: 1px solid $card-border-gray;
			}
		}
		.type {
			float: left;
			width: 50%;
			//box-shadow: 0 0 3px $gray;
			p {
				border-top: 1px solid $card-border-gray;
				border-bottom: 1px solid $card-border-gray;
				background: $card-bg-gray;
				padding: 7.5px 25px;
			}
			> div {
				padding: 10px 25px 20px;
			}
		}
	}
}
#flow {
	padding: 20px 0 60px;
	.right, .left {
		float: none;
	}
	.user, .company {
		text-align: center;
		span {
			border-radius: 5px;
			padding: 4px 50px;
		}
	}
	.user {
		span {
			border: 1px solid $black;
		}
	}
	.company {
		span {
			border: 1px solid $gray;
			color: $gray;
		}
	}
	.timeline {
		ul {
			background: $white;
			margin: 25px 0;
			position: relative;
			&:before {
				content: '\f017';
				font-family: "FontAwesome";
				position: absolute;
				top: -45px;
				left: 50%;
				transform: translateX(-50%);
				width: 30px;
				height: 30px;
				border-radius: 50%;
				background: #fff;
				font-size: 35px;
			}
			li {
				list-style-type: none;
				position: relative;
				width: 2px;
				margin: 0 auto;
				padding-top: 20px;
				background: $black;
				&:before {
					content: '';
					position: absolute;
					left: 50%;
					bottom: -6px;
					transform: translateX(-50%);
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background: inherit;
					z-index: 101;
					background: $white;
				}
				&:after {
					content: '';
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					width: 20px;
					height: 20px;
					border-radius: 50%;
					background: inherit;
					z-index: 102;
				}
				div {
					position: relative;
					bottom: 0;
					width: 400px;
					padding: 15px;
					background: $white;
					filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.2));
					p {
						margin-top: 10px;
						line-height: 1.25em;
					}
					&:before {
						content: '';
						position: absolute;
						bottom: 7px;
						width: 0;
						height: 0;
						border-style: solid;
					}
				}
			}
			li.right {
				span {
					margin-right: 10px;
					border: 1px solid $gray;
					color: $gray;
					border-radius: 0.1em;
					padding: 5px 10px;
					width: 100px;
				}
				div {
					left: -155px;
					&:before {
						left: -15px;
						border-width: 8px 16px 8px 0;
						border-color: transparent $white transparent transparent;
					}
				}
			}
			li.left {
				span {
					margin-right: 10px;
					background: $gray;
					color: $white;
					border-radius: 0.1em;
					padding: 5px 10px;
					width: 100px;
				}
				div  {
					left: -239px;
					&:before {
						right: -15px;
						border-width: 8px 0 8px 16px;
						border-color: transparent transparent transparent $white;
					}
				}
			}
		}
	}
	/* EFFECTS
	–––––––––––––––––––––––––––––––––––––––––––––––––– */
	.timeline {
		ul {
			li{
				&:after {
					transition: background .5s ease-in-out;
				}
				div {
					visibility: hidden;
					opacity: 0;
					transition: all .5s ease-in-out;
				}
			}
			li.in-view{
				&:after {
					background: $white;
					border: 3px solid $black;
				}
				div {
					transform: none;
					visibility: visible;
					opacity: 1;
				}
			}
			li.right div {
				transform: translate3d(200px, 0, 0);
			}
			li.left div {
				transform: translate3d(-200px, 0, 0);
			}
		}
	}
}
div.section {
	padding-bottom: 20px;
}
#plan {
	padding-bottom: 30px;
	button.btn {
		margin-top: 5px;
		padding: 15px 40px;
		margin-bottom: 10px;
	}
	input.room {
		width: 60px;
		margin-right: 5px;
	}
	> div {
		> div {
			text-align: center !important;
		}
	}
	.text-center {
		text-align: center !important;
	}
	.table {
		margin-bottom: 0;
	}
	.form-control {
		width: auto;
		display: inline;
	}
	.plan {
		padding-bottom: 30px;
		.panel {
    		text-align: center;
			box-shadow: $card-box-shadow;
			-moz-box-shadow: $card-box-shadow;
			-webkit-box-shadow: $card-box-shadow;
			border: none;
			h3 {
				margin-bottom: 0;
				color: $white;
			}
			&:hover {
				box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4), 0 1px 5px rgba(130, 130, 130, 0.35);
			}
		}
		.panel-heading {
			background: $black !important;
		}
		.panel-body {
		    padding: 0px;
		    text-align: center;
		}
		.panel-footer {
			background: $white;
		}
		.the-price {
		    background-color: rgba(220,220,220,.17);
			background: #f7f7f7;
		    box-shadow: 0 1px 0 #dcdcdc, inset 0 1px 0 #fff;
		    padding: 20px;
		    margin: 0;
			h1 {
				line-height: 1em;
				padding: 0;
				margin: 0;
			}
		}
		table.table {
			tr.active > td {
				background: #f7f7f7;
			}
		}
		.subscript {
    		font-size: 25px;
		}
	}
}
#contact {
	padding: 40px 0;
	div.contact-form {
		box-shadow: $card-box-shadow;
		-moz-box-shadow: $card-box-shadow;
		-webkit-box-shadow: $card-box-shadow;
		padding: 30px;
		background: $white;
		overflow: hidden;
	}
}
#merit, #voice, #flow, #plan, #contact {
	text-align: center;
	h2 {
		font-size: 24px;
		border-bottom: 4px solid $black;
		display: inline-block;
		padding-bottom: 0;
		margin-bottom: 30px;
	}
	> div {
		> div {
			text-align: left;
		}
	}
}
@media screen and (min-width: 992px) {
	#what {
		.col-md-6 {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
}
@media screen and (max-width: 991px) {
	#what {
		.minpakuWhat {
			div {
				width: 92%;
			}
		}
		.col-sm-6 {
			padding-left: 20px;
			padding-right: 20px;
		}
	}
	#flow {
		.timeline {
			ul {
				li {
					div {
						width: 300px;
					}
				}
				li.left {
					div {
						left: -139px;
					}
				}
			}
		}
	}
	.what-menu {
		display: none !important;
	}

	#patrol-top {
		.container{
			padding:0 10% 0 10%;
			width:100%;
		}
	}

}

@media screen and (max-width: 767px) {
	#what {
		h2,h3 {
			margin: 15px 0 5px;
		}
		h3 {
			font-size: 16px;
		}
		.minpakuWhat {
			margin-bottom: 20px;
			div {
				width: 100%;
				margin: 5px auto;
			}
		}
		.minpakuWhatImg {
			margin-bottom: 0;
		}
		.registerbtn{
			img {
				width: 80%;
			}
		}
	}
	#general.patrol {
		.uses {
			h2 {
				line-height: 1.5;
			}
		}
	}
	#general {
		#merits {
			.uses {
				p {
					img {
						width: 200px;
					}
				}
			}
		}
	}
	#column {
		ul {
			li {
				margin: 0 15px;
			}
		}
	}
	#merit {
		div.merit {
			> div {
				img {
					padding-top: 0;
				}
			}
		}
	}
	#flow {
		.flow {
			.user, .company {
				text-align: left;
				padding-bottom: 6px;
				span {
					background: $white;
					color: $black;
				}
			}
			ul {
				li {
					position:relative;
					margin: 15px 0 50px 0;
					padding: 10px;
					box-shadow: 0 0 3px $gray;
					line-height:23px;
					span {
						margin-right:7px;
						background: $black;
						color: $white;
						padding: 3px 5px;
					}
					&:nth-of-type(4){
						background-color:$color-e;
					}
					&:nth-of-type(5){
						background-color:$color-e;
					}
					&:after{
						content:"";
						position:absolute;
						display: block;
						left:50%;
						bottom:-25px;
						width: 18px;
						height: 18px;
						border-top: 2px solid $black;
						border-right: 2px solid $black;
						-webkit-transform: rotate(135deg) translateX(50%);
						transform: rotate(135deg) translateX(50%);
					}
					&:last-of-type:after{
						border:none;
					}
				}
			}
		}
	}

	#patrol-top {
		.container{
			padding:0;
			div{
		    	position:relative;
		    	height:170px;
				img{
					position:absolute;
					&.logo-v2{
						top:15%;
						left:50%;
						width:240px;
						transform: translateX(-50%);
					}
					&.patrol-title{
						top:auto;
						bottom:0%;
						left:50%;
						width:300px;
						transform: translateX(-50%);
					}
				}
			}
		}
	}
}

@media screen and (max-width: 480px) {
	.what-menu {
		display: none;
	}
	#minpaku-patrol, #merits p {
		.red.scroll {
			text-align: left !important;
    		display: initial !important;
		}
	}
	#value {
		canvas {
			display: none !important;
		}
	}
	.patrol-account {
		padding-bottom: 20px;
		a {
			width: 100%;
		}
	}
	.fee {
		div {
			margin-bottom: 5px;
			padding: 5px;
			font-size: 12px;
		}
	}
	#what {
		.minpakuWhat {
			div {
				font-size: 18px;
			}
		}
	}
	.chart {
		.result {
			margin: 0 -15px;
			ul {
				overflow: hidden;
				padding-bottom: 20px;
			}
		}
	}
	.plan {
		margin: 0 -15px;
	}
	#general.patrol {
		.content {
			padding: 30px 0;
		}
		.uses {
			b {
				padding: 0;
			}
		}
	}
	#voice {
		.voice {
			padding: 0 0 20px;
			.type {
				float: none;
				width: 100%;
			}
		}
	}

}
