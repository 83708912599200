.report-status {
	width: 100%;
	margin: 10px auto 0;
	th, td {
		width: 25%;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	th {
		border-bottom: 2px solid $thin-gray;
	}
}
.complaint-tab {
	padding: 20px 0;
	.nav-tabs {
		border-bottom: 1px solid $color-d;
		> li {
			text-align: center;
			width: 50%;
			a {
				margin-right: 0;
				border-radius: 0;
				color: $black;
				border-left: 1px solid $color-d;
				border-right: 1px solid $color-d;
				border-top: 1px solid $color-d;
			}
		}
		> li.active {
			> a {
				background: $thin-gray;
				border: 1px solid $color-d;
			}
		}
	}
}
.tab-content {
	.complaint {
		padding: 10px 5px;
		font-weight: 700;
		text-align: center;
		p {
			padding: 10px 0;
		}
		ul {
			li {
				background: $white;
				padding: 20px 0;
				span {
					padding-left: 0;
					padding-right: 0;
				}
			}
			li#btn {
				width: 60%;
				margin: 0 auto;
				.report-genre {
					ul {
						li {
							height: 120px;
							margin: 0;
							padding: 0;
							a {
								position: static;
								left: 0;
								top: 0;
								margin-left: 0;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}
	.comment {
		padding: 0 20px;
		font-weight: 400;
		text-align: left;
	}
	.report-genre {
		overflow: visible;
		margin-top: 0;
		ul {
			li {
				width: 100%;
				text-align: center;
				a {
					height: 120px;
					padding: 16px 20px;
				}
			}
		}
	}
	@for $i from 1 through 5 {
		.comment#{$i} {
			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.tab-content {
		.complaint {
			ul {
				li#btn {
					width: 90%;
				}
			}
		}
	}
}
@media screen and (max-width: 480px) {
	@for $i from 1 through 5 {
		.comment#{$i} {
			text-align: right;
			float: right;
		}
	}
	.tab-content {
		.complaint {
			ul {
				li#btn {
					width: 100%;
					margin: 0 auto;
				}
			}
		}
		.report-genre {
			ul {
				li {
					a {
						height: 100px;
						padding: 18px 20px;
						font-size: 18px;
					}
				}
			}
		}
		.complaint {
			ul {
				li#btn {
					.report-genre {
						ul {
							li {
								height: 110px;
							}
						}
					}
				}
			}
		}
	}
}
