.backendShow {
	table.table {
		td {
			padding: 0;
		}
	}
	p {
		padding: 0 10px;
	    word-break: break-all;
	}
	dl {
		clear: both;
		overflow: hidden;
		border-bottom: 1px solid #ddd;
		dt {
			padding: 0;
		}
		dd {
			border-left: 1px solid #ddd;
			padding: 0;
			ul {
				li {
					float: left;
					width: 33.3333333333%;
					border-right: 1px solid #ddd;
					border-bottom: 1px solid #ddd;
					&:nth-child(3n) {
						border-right: none;
					}
					&:nth-child(4),&:nth-child(5),&:nth-child(6) {
						border-bottom: none;
					}
				}
			}
		}
	}
	.reason {
		overflow: hidden;
		margin-bottom: 20px;
	}
}
.backendMaster {
	overflow: hidden;
	margin: 0 0 20px;
}
.left {
	float: left;
}
.fact {
	h3 {
		margin-top: 10px;
	}
	.form-control {
		margin-top: 10px;
	}
}
ul.patrol-room-list {
	li {
		border-bottom: 1px solid $black;
		overflow: hidden;
		padding: 5px 0;
	}
}
#ident-images {
	overflow: hidden;
	margin: 10px 0;
	.quotation-url {
		margin-top: 5px;
	}
}
.addImg {
	margin-bottom: 10px;
}
.specific {
	.btn {
		line-height: 1.428571429;
	    padding: 6px 12px;
	    margin: 0;
	    font-size: 14px;
	}
	.spe-img {
		margin-top: 10px;
		width: 300px;
		height: 200px;
		background-repeat: no-repeat;
		background-position: top;
		background-size: cover;
		float: left;
	}
}
ul.month {
	li {
		padding: 0;
	}
}
.specific-img {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	width: 300px;
	height: 200px;
	float: left;
}
table.table.table-striped {
	> tbody {
		> tr {
			> td {
				word-break: break-all;
			}
		}
	}
}

#backend {
	#plan-list {
		.top-list {
			.col-sm-6 {
				width: 100%;
			}
			.panel {
				height: auto;
				overflow: hidden;
			}
		}
	}
	.top-list {
		.panel {
			height: 300px;
			overflow-y: scroll;
		}
	}
	.specific {
		label {
			margin-top: 18px;
		}
	}
	#identi {
		#propertyForm {
			.upload-btn {
				top: 0;
				left: 0;
				width: 100%;
			}
			.remove {
				top: 5px;
			}
		}
	}
	.thumb {
		width: 100%;
		height: 100%;
	}
}
