html {
	height: 100%;
}
body {
    min-height: 100%;
	display: flex;
	flex-direction: column;
	font-family: $fontType1;

}
img {
	max-width: 100%;
}
html, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
    margin:0;
    padding:0;
    border:0;
    outline:0;
    font-size:100%;
    vertical-align:baseline;
    background:transparent;
}
h1, h2, h3, h4, h5, h6 {
    font-family: inherit;
    font-weight: 700;
    line-height: 1.5;
    color: inherit;
}
h1 {
	font-size: 24px;
	padding: 30px 0 10px;
}

h2 {
	font-size: 20px;
	padding: 30px 0 10px;
}
ol, ul, li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

a {
	color: $red;
	&:hover {
		color: $red;
		text-decoration: underline;
	}
}

span.bold {
    font-weight: 700;
}

.thumb-hide {
	display: none;
}
#logo {
	overflow: hidden;
	padding-left: 30px;
		> li {
			width: auto;
			> a {
				padding: 0;
				border-left: none;
				margin-top: 0;
				div {
					line-height: 1;
					small {
						font-size: 12px;
					}
					p {
						font-size: 22px;
						line-height: 1.4;
					}
				}
			}
		}
}

.balloon{
    background: #fff;
    display: inline-block;
    position: relative;
    width: auto;
    height: 60px;
    border: 2px solid $color-d;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 5px;
	&:before {
		content: "";
		display: inline-block;
		border: 10px solid transparent;
		border-top-color: $color-d;
		position: absolute;
		left: 80px;
		bottom: -20px;
	}
	&:after {
	    content: "";
	    display: inline-block;
	    border: 10px solid transparent;
	    border-top-color: $white;
	    position: absolute;
	    left: 80px;
	    bottom: -18px;
	}
}
.balloon-link{
  &:hover{
    border: 2px solid $black;
  }
  &:hover:before {
    content: "";
    display: inline-block;
    border: 10px solid transparent;
    border-top-color: $black;
    position: absolute;
    left: 80px;
    bottom: -20px;
  }
}
@media screen and (max-width: 1080px) {
	#logo {
		padding-left: 0;
	}
}
.myPage {
	color: $white;
	p {
		text-shadow: none;
		font-size: 18px;
		line-height: 64px;
	}
}
#wrapper {
	margin-top: 0;
	overflow: hidden;
	// flex: 1;
}
.row {
	margin-left: 0;
	margin-right: 0;
}
.content {
	margin: 0 auto;
	padding: 30px 0 0 0;
	// overflow: hidden;
}
.sp {
	display: none;
}
.left {
	float: left;
}
.middle {
	vertical-align: middle;
	display: inline-block;
}
.middle-flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.rela {
	position: relative;
}
.abso {
	position: absolute;
}
.overflow {
	overflow: hidden;
}
.bottom {
	right: 0;
	bottom: 0;
}
.red {
	color: $red;
}
.under {
	text-decoration: underline;
}
.font-ll {
	font-size: 25px;
}
.font-l {
	font-size: 21px;
}
.font-l-sidebar {
	font-size: 18px;
    font-weight: 500;
}
.font-m {
	font-size: 18px;
}
.font-s{
	font-size: 16px;
}
.font-xs-with-padding{
  font-size: 15px;
  padding-bottom: 4px;
  padding-top: 4px;

}
.fontType1 {
	border-bottom: 4px solid $black;
	padding-bottom: 10px;
}
.font-xl {
	font-size: 32px;
}
.font-xxl {
	font-size: 68px;
}
.black {
	color: $black;
}
.white {
	color: $white;
}
.right {
	float: right;
}
.white {
	color: #fff;
}
.mb20 {
	margin-bottom: 20px;
}
.mb40 {
	margin-bottom: 40px;
}
.mr10 {
	margin-right: 10px;
}
.required {
	background: $red;
	color: $white;
	padding: 3px 8px;
	margin-left: 7px;
}
.clear-text {
	text-indent:100%;
	white-space:nowrap;
	overflow:hidden;
}
.border-left {
	border-left: 1px solid $gray;
}
.form-group {
	overflow: hidden;
	font-size: 14px;
	line-height:22px;
	h3{
		font-size: 14px;
	}
	p{
		font-size: 14px;
		line-height:20px;
	}
}

.border {
	border-bottom: 2px solid $color-e;
}
.gray {
	color: $gray;
	font-weight: 700;
}
.white {
	color: $white;
}
.bg-red {
	background: $red;
}
.bg-aqua {
	background: $aqua;
}
.bg-green {
	background: $green;
}
.bg-gray {
	background: $color-d;
}
.bg-white {
	background: $white;
}
.bg-black {
	background: $bg-black;
	color: $white;
}
.bg-thin-gray {
	background: $sola-bg;
}
.btn-danger {
	background-color: $red;
    border-color: $red;
}
.btn-primary {
	background-color: $blue;
    border-color: $blue;
}
.btn-border {
	background: $white;
	border: 1px solid $red;
	color: $red;
	text-align: center;
	line-height: 25px;
	padding: 5px 0;
	margin: 5px 0;
}
.btn-gray-border {
	background: $gray;
	border: 1px solid $gray !important;
	color: $white;
	text-align: center;
	padding: 10px 0;
}
.gray-btn {
	background-color: $gray;
	color: #FFF;
	&:hover{
		opacity: 0.8;
		color: #FFF;
	}
	&:focus{
		color: #FFF;
	}
}
.gray-btn {
	background-color: $gray;
	color: #FFF;
	&:hover{
		opacity: 0.8;
		color: #FFF;
	}
	&:focus{
		color: #FFF;
	}
}
.green-btn {
	background-color: $green-btn-background;
	color: #FFF;
	&:hover{
		opacity: 0.8;
		color: #FFF;
	}
	&:focus{
		color: #FFF;
	}
}
.red-btn {
	background-color: $red;
	color: #FFF;
	&:hover{
		opacity: 0.8;
		color: #FFF;
	}
	&:focus{
		color: #FFF;
	}
}
.yellow-btn {
	background-color: $yellow-btn-background;
	color: #FFF;
	&:hover{
		opacity: 0.8;
		color: #FFF;
	}
	&:focus{
		color: #FFF;
	}
}
.no-link {
	&:hover {
		cursor: default;
		text-decoration: none;
	}
}
.pageTop {
	margin: 0 10px 10px;
}
button.btn {
	padding: 15px 0;
}
#flashMessage {
	overflow: hidden;
	margin-bottom: 20px;
}
.message  {
	padding: 15px;
	margin-bottom: 20px;
	border: 1px solid transparent;
	border-radius: 4px;
}
.success {
	color: #3c763d;
	background-color: #dff0d8;
	border-color: #d6e9c6;
}
.error {
	color: #a94442;
	background-color: #f2dede;
	border-color: #ebccd1;
}

.form-control {
	color: $input;
}
.panel-danger {
	border-color: $red;
	> .panel-heading {
		color: $white;
		background-color: $red;
		border-color: $red;
		h2 {
			padding: 0;
		}
	}
}
.remodal-close {
	right: 0;
	left: auto !important;
}
@for $i from 1 through 10 {
	.col-#{$i} { width: 10% * $i; }
}
@media (min-width: 1200px) {
	.container {
		width: 950px;
	}
}
@media screen and (min-width: 993px) and (max-width: 1139px) {
	.font-xxl {
		font-size: 60px;
	}
	.font-xl {
		font-size: 27px;
	}
}
@media screen and (min-width: 993px) {
	.container {
		width: 950px;
	}
	.mobile-no-brake {
		display: none;
	}
}
@media screen and (min-width: 768px) and (max-width: 992px) {
	.container {
		width: auto;
	}
}
@media screen and (max-width: 992px) {
	footer {
		.copyright {
			position: static;;
			bottom: 0;
			right: 0;
			padding: 0;
		}
	}
	#wrapper {
		margin-top: 0;
	}
	.content {
		width: 100%;
	}
	.font-xxl {
		font-size: 48px;
	}
	.font-xl {
		font-size: 23px;
	}
}
@media screen and (max-width: 767px) {
	.sp {
		display: block;
	}
	.pc {
		display: none;
	}
	.possibility{
		font-size: 15px;
	}
	.font-xl{
		font-size: 25px;
	}
	.font-ll{
		font-size: 21px;
	}
	.font-l{
		font-size: 18px;
	}
	.font-m{
		font-size: 16px;
	}
	.font-s{
		font-size: 12px;
	}
	.mb20 {
		margin-bottom: 10px;
	}
}

@media screen and (max-width: 480px) {
	#wrapper {
		padding: 0;
	}
	.content {
	    margin: 0 auto;
	    overflow: hidden;
	}
	.col-xxs-12 {
		width: 100%;
	}
	.col-xxs-11 {
		width: 91.66666667%;
	}
	.col-xxs-10 {
		width: 83.33333333%;
	}
	.col-xxs-9 {
		width: 75%;
	}
	.col-xxs-8 {
		width: 66.66666667%;
	}
	.col-xxs-7 {
		width: 58.33333333%;
	}
	.col-xxs-6 {
		width: 50%;
	}
	.col-xxs-5 {
		width: 41.66666667%;
	}
	.col-xxs-4 {
		width: 33.33333333%;
	}
	.col-xxs-3 {
		width: 25%;
	}
	.col-xxs-2 {
		width: 16.66666667%;
	}
	.col-xxs-1 {
		width: 8.33333333%;
	}
}
