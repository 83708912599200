#report-form {
	h3 {
		margin: 0;
	}
	label {
		margin: 10px 0;
	}
	.mb20 {
		margin-bottom: 10px;
	}
}
/* confirm */
#confirmation {
	overflow: hidden;
	dl {
		margin: 5px 0;
		dt,dd {
			padding: 10px;
		}
		dt {
			text-align: right;
		}
	}
}
.btn-revise {
	color: #000;
    margin: 0 auto;
    padding: 10px 0 10px 0;
    background: #fff;
    width: 40%;
    line-height: 0.7;
    font-size: 33px;
    font-weight: 700;
    box-shadow: 4px 3px 3px #999;
    border: 6px solid #1a1a1a;
}
.btn-report {
    margin: 20px 10px;
    border: 6px solid #1a1a1a;
    width: 45%;
    line-height: 2;
    font-size: 33px;
    font-weight: 700;
    box-shadow: 4px 3px 3px #999;
    background: #fff;
    line-height: 10px;
}
.btn-report .white {
    font-size: 18px;
    color: #fff;
}
a.btn-report {
	color: #1a1a1a;
}
.notice {
	margin: 10px 0;
	background: #FFFF0B;
	line-height: 1.5;
	text-align: center;
	font-weight: 700;
}
@media screen and (max-width: 767px) {
	#report-form {
		label {
			margin: 5px 0;
		}
		span.complaint-item {
			width:100%;
			label {
				margin: 0;
			}
		}
		.autoSelect {
			margin-bottom: 5px;
		}
	}
	#confirmation {
		img {
			width: 50%;
		}
		dl {
			margin: 10px 0;
			overflow:hidden;
			dt,dd {
				padding: 0;
			}
			dt {
				text-align: left;
			}
		}
	}
	.btn-revise,.form .btn-report {
		width: 100%;
		font-size: 18px;
		margin-bottom: 10px;
		line-height: 1.2;
	}
	a.btn-report {
		width: 45%;
		margin: 5px;
		padding: 10px;
		font-size: 14px;
	}
	.form{
		.btn-report {
			font-size: 26px;
			.white {
				font-size: 14px;
			}
		}
	}

}
