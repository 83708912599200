.top-alert {
	border: 1px solid $black;
	margin-bottom: 20px;
	h3 {
		padding: 10px 0;
		font-size: 20px;
	}
	div {
		background: $black;
		padding: 10px 0 ;
		font-size: 20px;
		a {
			color: $white;
			i {
				color: $yellow;
				font-size: 32px;
			}
		}
	}
}
.tab{
	border-top: 1px solid $black;
	border-left: 1px solid $black;
	border-right: 1px solid $black;
	overflow:hidden;
	li {
		background:$white;
		padding:5px 25px;
		float:left;
		width: 50%;
		border-bottom: 1px solid $black;
		&:last-child {
			border-left: 1px solid $black;
		}
	}
	li.select {
		background:$color-e;
		border-bottom: 1px solid $color-e;
	}
}
.tab-content {
	// border-left: 1px solid $black;
	// border-right: 1px solid $black;
	// border-bottom: 1px solid $black;
	margin-bottom: 30px;
	li{
		background:$color-e;
		padding:20px;
		overflow: hidden;
	}
}
.area-btn {
	padding: 10px;
	display: block;
	border-radius: 5px;
	color: $white;
}
.patrol-list {
	width: 50%;
	margin: 0 auto;
	padding-top: 30px;
	a {
		padding: 10px 0;
	}
}
#top{
	position: relative;
	h1 {
		letter-spacing: 10px;
	}
	.what-btn {
		position: absolute;
		left: 50%;
		margin-left: -100px;
		a {
			width: 200px;
			background: $gray-btn-background;
			text-align: center;
			color: $white;
			display: block;
			line-height: 2.5;
		}
	}
}
.report-info {
	margin: 20px auto;
	width: 70%;
	span {
		width: 10%;
		color: $thin-black;
	}
	.fact {
		font-family: 'Droid Serif';
	}
	.font-ll {
		font-size: 35px;
	}
}
.report-info.gray {
	font-weight: 400;
}
#branch {
	.report-genre {
		ul {
			margin-top: 30px;
			li {
				height: 200px;
			}
		}
	}
}
.report-genre {
	overflow: hidden;
	margin-top: 20px;
	ul {
		overflow: hidden;
		margin: 0 auto;
		width: 890px;
		li {
			float: left;
			padding: 0;
			img {
				height: 80px;
				text-align: center;
				margin-bottom: 5px;
			}
			img#punch {
				height: 66px;
				margin-top: 5px;
				margin-bottom: 13px;
			}
			a {
				margin-left: 30px;
				display: block;
				border-radius: 15px;
				line-height: 1.2;
				color: $black;
				font-size: 16px;
				padding: 36px 10px;
				// height: 200px;
				width: 190px;
				background: $white;
				border: 2px solid $color-d;
				&:hover {
					border: 2px solid $black;
					img {

					}
				}
			}
			a.genre3 {
				div {
					position: absolute;
					top: 15px;
					right: 15px;
					font-weight: 700;
					font-size: 24px;
				}
			}
			p.red {
				font-size: 20px;
				span {
					color: $black;
				}
			}
			.balloon {
				padding: 0 8px;
				text-align: center;
				height: 70px;
				width: 200px;
				position: absolute;
				bottom: 220px;
				left: 50%;
				margin-left: -100px;
				line-height: 65px;
				font-size: 12px;
                &:hover {

                }
				span {
					padding: 7px;
				}
                a {
                  margin-left: inherit;
                  display: inherit;
                  line-height: inherit;
                  color: $black;
                  background: none;
                  border: 0;
                  font-size: 14px;
                  font-weight: 600;
				  position: relative;
				  left: 0;
				  height: auto;
				  width: auto;
				  padding: 0;
				  margin: 0;
                }
                a:hover {
                  text-decoration: underline;
                }
			}
		}
		li.btn-report {
			width: 30%;
			padding: 10px;
		}
	}
	.font-ll {
		font-size: 48px;
	}
}
#link-menu {
	text-align: center;
	padding: 20px 0;
	background: #fcfcfc;
	box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.15) inset;
	margin-left: -1rem;
	margin-right: -1rem;
	.container {
		width: 780px;
		.col-left, .col-right {
			float: left;
			position: relative;
			min-height: 1px;
			padding-left: 10px;
			padding-right: 10px;
		}
		.col-left {
			width: 22.5%;
		}
		.col-right {
			width: 77.5%;
		}
	}
	.genre {
		a {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			i {
				font-size: 3.25rem;
			}
		}
	}
	.upper, .lower {
		overflow: hidden;
		background: $gray-2;
		padding: 7.5px 2.5px;
		border-radius: 7px;
	}
	.upper {
		margin-bottom: 7.5px;
	}
	.lower {
		margin-top: 7.5px;
	}
	span {
		color: $gray-3;
		float: left;
		-webkit-writing-mode: vertical-rl;
		-ms-writing-mode: tb-rl;
		writing-mode: vertical-rl;
		margin-top: 14px;
		margin-left: 4px;
	}
	ul {
		margin-left: 30px;
		li {
			float: left;
			padding: 0 5px;
			width: 33.333333%;
		}
	}
	a {
		padding: 15px 10px;
		display: block;
		background: $white;
		color: $black;
		border: 2px solid $border;
		border-radius: 7px;
		height: 9.5rem;
		img {
			height: 45px;
			margin: 0.5rem;
		}
		i {
			color: $black;
		}
		&:hover {
			color: $black;
			text-decoration: none;
			border-color: $black;
		}
	}
	i {
		font-size: 2.9em;
		margin: 0.8rem;
	}
}
#media{
	margin: 0 auto;
	padding: 50px 0 30px;
	width: 560px;
	text-align: center;
	p {
		padding: 5px 0;
		span {
			width: 200px;
			display: inline-block;
		}
	}
	#television, #magazine {
		position: relative;
		margin: 20px 0;
		.icon {
			position: absolute;
			top: 50%;
			left: 0;
			margin-top: -30px;
			img {
				height: 60px;
			}
		}
	}
}
#media{
	padding: 50px 0 30px;
}
#television, #magazine{
	table{
		width: 55%;
		min-width: 285px;
		margin-top: 10px;
		margin-left: 19%;

		tr{
			text-align: center;
		}
		td{
			border: none;
		}
		img{
			height: 60px;
		}
	}
}
#icontd{
	width: 20%;
	text-align: left;
	position: relative;
}
#magazine{
	table{
		img{
			position: absolute;
			top: 50%;
			margin-top: -30px;
		}
	}
}

#what {
	text-align: center;
	overflow: hidden;
	.title {
		margin: 20px 0;
	}
	ul {
		li {
			p {
				font-weight: 700;
			}
			img {
				height: 100px;
			}
		}
	}
}
.what {
	p {
		margin: 10px 0;
	}
}

.no-margin {
	margin: 0 !important;
}
.perform ul {
	overflow: hidden;
	text-align: center;
	li {
		float: left;
		width: 25%;
		padding: 10px;
		img {
			height: 80px;
		}
	}
	span {
		font-weight: 700;
	}
}
.point {
	margin-top: 35px;
	line-height: 1.5;
	text-align: left;
	p {
		span {
			display: block;
			margin-left: 20px;
		}
	}
	ul {
		li {
			list-style-type: decimal-leading-zero;
			margin-left: 45px;
		}
	}
}
.possibility {
	overflow: hidden;
}
.possibility li {
	padding: 4px 0;
}
@media screen and (max-width: 991px) {
	.report-genre {
		ul {
			li {
				padding: 10px 0;
			}
		}
	}
}
@media screen and (max-width: 890px) {
	.report-genre ul {
		width: 100%;
		li {
			width: 25%;
			a {
				width: 100%;
				margin-left: 0;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	#top {
		margin-top: 30px;
		.font-xl {
			font-size: 21px;
		}
	}
	.patrol-list {
		width: 100%;
		margin: 0 auto;
		padding-top: 10px;
		a {
			padding: 10px 0;
		}
	}
	.report-info {
		width: 100%;
		margin: 10px auto;
		span {
			float: left;
			margin-top: 5px;
			&:nth-child(4),&:nth-child(6),&:nth-child(8) {
				width: 20%;
			}
			&:nth-child(3),&:nth-child(5),&:nth-child(7), &:last-child {
				width: 10%;
			}
		}
		.font-ll {
			font-size: 26px;
		}
	}
	#media{
		width: auto;
	}
	#television, #magazine{
		table{
			margin: 0 auto;
		}
	}
	.report-genre {
		ul {
			width: 100%;
			li {
				width: 33.33333%;
				padding-left: 10px;
				a {
					margin-left: auto;
					margin-right: auto;
					width: 100%;
				}
				a.genre3 {
					position: relative;
				}
				.balloon {
					position: static;
					bottom: 0;
					left: 0;
					margin-left: auto;
					margin-bottom: 20px;
					height: 70px;
					padding-top: 5px;
					line-height: 2;
					&:before {
						top: 90px;
						left: 100px;
					}
					&:after {
						top: 88px;
						left: 100px;
					}
				}
			}
		}
	}
	.perform {
		ul {
			li {
				width: 50%;
			}
		}
	}
}
@media screen and (max-width: 480px) {
	#top{
		.font-xl {
			font-size: 18px;
		}
		.font-m {
			font-size: 14px;
		}
		.mb20 {
			margin-bottom: 5px;
		}
		#television, #magazine{
			table{
				img{
					height: 45px;
				}
			}
		}
		#magazine{
			table{
				img{
					margin-top: -22.5px;
				}
			}

		}
	}
	.perform {
		ul {
			li {
				img {
					height: 35px;
				}
				p {
					font-size: 12px;
					margin: 10px 0;
				}
			}
		}
	}
	#media{
		p {
			padding: 5px 0;
			span {
				width: auto;
				padding: 0 10px;
			}
		}
		#television, #magazine {
			.icon {
				margin-top: -23px;
				img {
					height: 45px;
				}
			}
			.media-content {
				padding-left: 50px;
			}
		}
	}
	#hidden-div{
		display: block!important;
	}
	.report-info.font-m {
		font-size: 14px;
	}
	.report-info {
		margin: 5px auto;
	}
	.report-genre {
		margin-top: 0;
		ul {
			li {
				padding: 10px 0;
				margin: 0 1%;
				width: 48%;
				// box-shadow: 0 0 8px $box-shadow;
				a {
					font-size: 12px;
					padding: 20px 0;
					width: auto;
					img {
						height: 35px;
						margin-top: 5px;

					}
					img#punch {
						height: 35px;
						margin-bottom: 5px;
					}
				}
				&:nth-child(3), &:nth-child(4) {
					height: 230px;
				}
				a.genre3 {
					div {
						font-size: 16px;
					}
				}
				.balloon {
					position: absolute;
					bottom: 0;
					left: 0;
					margin-top: 10px;
					width: 100%;
					&:before {
						border-bottom-color: $color-d;
						border-top-color: transparent;
						top: -20px;
						bottom: auto;
					}
					&:after {
						border-bottom-color: $white;
						border-top-color: transparent;
						top: -18px;
						bottom: auto;
					}
				}
			}
		}
	}
	.point {
		ul {
			li {
				margin-left: 30px;
			}
		}
	}
}
